import * as React from "react";

export default function Contact({ location }) {
  const params = new URLSearchParams(location?.search);
  const success = params.get("success");

  return (
    <div className="pt-16 overflow-hidden">
      <div className="relative py-16 px-6 lg:mx-auto lg:max-w-7xl lg:px-8">
        <div className="lg:pr-8">
          <div className="mx-auto max-w-2xl">
            {success && (
              <div className="rounded-md bg-green-50 p-4 mb-8">
                <div className="flex">
                  <div className="ml-3">
                    <h3 className="text-sm font-medium text-green-800">
                      Your contact form was successfully submitted. We will get back to you!
                    </h3>
                  </div>
                </div>
              </div>
            )}
            <h2 className="text-3xl font-bold tracking-tight sm:text-4xl">Contact us</h2>
            <p className="mt-4 text-lg text-gray-500 sm:mt-3">
              We’d love to hear from you! Send us a message and we will get back to you.
              Alternatively, you can email us on{" "}
              <a
                href="mailto:help@meetslack.com"
                className="font-medium text-indigo-600 hover:text-indigo-500"
              >
                help@meetslack.com
              </a>{" "}
              .
            </p>
            <form
              name="contact"
              action="/contact?success=true"
              netlify-honeypot="bot-field"
              method="POST"
              data-netlify="true"
              className="mt-9 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8"
            >
              <input type="hidden" name="form-name" value="contact" />
              <input type="hidden" name="bot-field" />
              <input type="hidden" name="subject" value="Meet Slack contact form" />
              <div>
                <label htmlFor="first-name" className="block text-sm font-medium text-gray-700">
                  First name
                </label>
                <div className="mt-1">
                  <input
                    type="text"
                    name="first-name"
                    id="first-name"
                    autoComplete="given-name"
                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                  />
                </div>
              </div>
              <div>
                <label htmlFor="last-name" className="block text-sm font-medium text-gray-700">
                  Last name
                </label>
                <div className="mt-1">
                  <input
                    type="text"
                    name="last-name"
                    id="last-name"
                    autoComplete="family-name"
                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                  />
                </div>
              </div>
              <div className="sm:col-span-2">
                <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                  Email
                </label>
                <div className="mt-1">
                  <input
                    required
                    id="email"
                    name="email"
                    type="email"
                    autoComplete="email"
                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                  />
                </div>
              </div>
              <div className="sm:col-span-2">
                <label htmlFor="company" className="block text-sm font-medium text-gray-700">
                  Company
                </label>
                <div className="mt-1">
                  <input
                    type="text"
                    name="company"
                    id="company"
                    autoComplete="organization"
                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                  />
                </div>
              </div>
              <fieldset className="sm:col-span-2">
                <legend className="block text-sm font-medium text-gray-700">
                  How many users are in your Slack?
                </legend>
                <div className="hidden">
                  <input type="radio" name="team" />
                  <label hidden>How many users are in your Slack?</label>
                </div>
                <div className="mt-4 grid grid-cols-1 gap-y-4">
                  <div className="flex items-center">
                    <input
                      id="less-than-100"
                      name="team"
                      defaultValue="Less than 100"
                      type="radio"
                      className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-500"
                    />
                    <label htmlFor="less-than-100" className="ml-3">
                      <span className="block text-sm text-gray-700">Less than 150 users</span>
                    </label>
                  </div>
                  <div className="flex items-center">
                    <input
                      id="100-500"
                      name="team"
                      defaultValue="Between 100 and 500 users"
                      type="radio"
                      className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-500"
                    />
                    <label htmlFor="100-500" className="ml-3">
                      <span className="block text-sm text-gray-700">Between 151 and 500 users</span>
                    </label>
                  </div>
                  <div className="flex items-center">
                    <input
                      id="more-than-500"
                      name="team"
                      defaultValue="More than 500 users, or enterprise spread across more teams"
                      type="radio"
                      className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-500"
                    />
                    <label htmlFor="more-than-500" className="ml-3">
                      <span className="block text-sm text-gray-700">
                        More than 500 users, or enterprise spread across more teams
                      </span>
                    </label>
                  </div>
                </div>
              </fieldset>
              <div className="sm:col-span-2">
                <div className="flex justify-between">
                  <label
                    htmlFor="how-can-we-help"
                    className="block text-sm font-medium text-gray-700"
                  >
                    How can we help you?
                  </label>
                </div>
                <div className="mt-1">
                  <textarea
                    required
                    id="how-can-we-help"
                    name="how-can-we-help"
                    aria-describedby="how-can-we-help-description"
                    rows={4}
                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    defaultValue={""}
                  />
                </div>
              </div>

              <div className="text-right sm:col-span-2">
                <button
                  type="submit"
                  className="inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

import React from "react";
import AllTestimonals from "../testimonials/All";

export default function Success({ location }) {
  const params = new URLSearchParams(location?.search);
  const error = params.get("error");

  return (
    <>
      <div className="bg-white min-h-full px-8 pt-48 pb-16 sm:px-12 md:grid md:place-items-center">
        <div className="py-4 max-w-max mx-auto">
          {error && (
            <div className="rounded-md bg-red-50 p-4 mb-8">
              <div className="flex">
                <div className="ml-3">
                  <h3 className="text-sm font-medium text-red-800">There was an error...</h3>
                  <div className="mt-2 text-sm text-red-700">{error}</div>
                </div>
              </div>
            </div>
          )}
          <main className="sm:flex">
            <p className="text-4xl font-extrabold text-indigo-600 sm:text-5xl">🚀</p>
            <div className="sm:ml-6">
              <div className="sm:border-l sm:border-gray-200 sm:pl-6">
                <h1 className="text-4xl font-extrabold text-gray-900 tracking-tight sm:text-5xl">
                  Installation completed
                </h1>
                <p className="mt-3 text-xl text-gray-500">
                  Start your first meeting by sending /meet in Slack.
                </p>
              </div>
              <div className="mt-10 flex space-x-3 sm:border-l sm:border-transparent sm:pl-6">
                <a
                  href="/"
                  className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  Go home
                </a>
                <a
                  href="https://meet.projectundefined.com/install?plan=auto"
                  className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  Subscribe to a paid plan
                </a>
              </div>
            </div>
          </main>
        </div>
      </div>
      <AllTestimonals />
    </>
  );
}
